import React from 'react';


function App() {

  return (
    <section id="resume">
        <div className="row">
          <h2><span>Accreditaion</span></h2>
          <p>Discover the credentials that demonstrate our expertise.</p><br/>
          <div className="xs-bgrid-halves bgrid-quarters s-bgrid-quartrse">
          <div className="column"><img src="/images/adm1.png" alt=""></img></div>
          <div className="column"><img src="/images/adm2.png" alt=""></img></div>
          <div className="column"><img src="/images/dev1.png" alt=""></img></div>
          <div className="column"><img src="/images/cpq1.png" alt=""></img></div>
          <div className="column"><img src="/images/dep1.png" alt=""></img></div>
          </div>
        </div>
    </section>
  );
};

export default App;