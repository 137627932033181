import React, { Component } from "react";

class Update extends Component {    
  render() {

    return (
      <section id="pricing">
        <h3><span>Pricing</span></h3>
        <table className="pricing-table">
      <thead>
        <tr>
          <th>Service</th>
          <th>Hourly Charge</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Salesforce Administration</td>
          <td>£30/hr</td>
        </tr>
        <tr>
          <td>Salesforce Development</td>
          <td>£40/hr</td>
        </tr>
      </tbody>
    </table>
      </section>
    );

  }
}

export default Update;