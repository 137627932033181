import React, { Component } from "react";


class Logo extends Component {
  render() {
    if (!this.props.data) return null;

    const logo = "images/" + this.props.data.logos.headerlogo;

    return (
      <section id="logo">        
      <img src={logo} alt="CB logo"></img>
      </section>
    );
  }
}

export default Logo;
