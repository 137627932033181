import React, { Component } from "react";
import Fade from "react-reveal";
import {Collapse} from 'react-collapse';
import Pricing from "./Sections/Pricing";
import ContactForm from "./Sections/ContactForm";
import Update from "./Sections/Update";


class How extends Component {

  constructor(props) {
    super(props);
    this.state = {isPricebookOpen : false, 
                  isContactOpen : false, 
                  isUpdateOpen : false 
                };
    this.handlebuttons = this.handlebuttons.bind(this);
  }

  handlebuttons(e) {
    // eslint-disable-next-line default-case
    switch (e) {
      case 'pricebookButton':
        if(this.state.isPricebookOpen === false){
          this.setState({isPricebookOpen: true, isContactOpen : false, isUpdateOpen:false });
          }
          else if(this.state.isPricebookOpen === true){
            this.setState({isPricebookOpen: false});
          } 
        break;
      case 'contactButton':
        if(this.state.isContactOpen === false){
          this.setState({isContactOpen: true, isPricebookOpen : false, isUpdateOpen:false });
          }
          else if(this.state.isContactOpen === true){
            this.setState({isContactOpen: false});
          } 
        break;
      case 'updateButton':
        if(this.state.isUpdateOpen === false){
          this.setState({isUpdateOpen: true, isPricebookOpen : false, isContactOpen:false });
          }
          else if(this.state.isUpdateOpen === true){
            this.setState({isUpdateOpen: false});
          } 
        break;
    }
  }


  render() {
    if (!this.props.data) return null;

    const {isPricebookOpen} = this.state;
    const {isContactOpen} = this.state;
    const {isUpdateOpen} = this.state;
    const height = 30;

    const image1 = "images/" + this.props.data.aboutimg.img1;
    const image2 = "images/" + this.props.data.aboutimg.img2;
    const image3 = "images/" + this.props.data.aboutimg.img3;
    const process = this.props.data.process;
    const contacth = this.props.data.contactheader;
    const contact1 = this.props.data.contact1;
    const contact2 = this.props.data.contact2;
    const contact3 = this.props.data.contact3;


    return (



      <section id="How">
        <div className="row">
          <Fade duration={3000}>
              <h2><span>Process</span></h2>
              <p>{process}</p><br/>
              <div className="row">
              <div className="four column"><img alt='' src={image3}></img><button  class="button-54" onClick={ () => this.handlebuttons('updateButton')} >Services</button><div id="how"><Collapse  isOpened={isUpdateOpen}> <div style={{height}} className="blob" /><Update/></Collapse></div></div>
              <div className="four column"><img alt='' src={image1}></img><button  class="button-54" onClick={ () => this.handlebuttons('pricebookButton')} >Pricebook</button><div id="pb"><Collapse  isOpened={isPricebookOpen}><div style={{height}} className="blob" /><Pricing/></Collapse></div></div>
              <div className="four column"><img alt='' src={image2}></img><button  class="button-54" onClick={ () => this.handlebuttons('contactButton')} >Contact</button><div id="con"><Collapse  isOpened={isContactOpen}><div style={{height}} className="blob" /><ContactForm /><h3><span>{contacth}</span></h3><p>{contact1}</p><p>{contact2}</p><p>{contact3}</p>
            </Collapse>
            </div>
            </div>
            </div>
            </Fade>
            <div id="pbmax">
              <Collapse  isOpened={isPricebookOpen}>
              <div style={{height}}/><Pricing/>
              </Collapse>
              <Collapse   isOpened={isUpdateOpen}>
              <div style={{height}} className="blob" /><Update/>
              </Collapse>
              <Collapse  isOpened={isContactOpen}>
              <div style={{height}} className="blob" /> 
              <h3><span>{contacth}</span></h3><p>{contact1}</p><p>{contact2}</p><p>{contact3}</p>
              <h3><span>Contact Form</span></h3>
              <ContactForm /> 
              </Collapse>
              </div>
          </div>
            

      </section>

    );

  }
}

export default How;