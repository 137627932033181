import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {    


  render() {
    if (!this.props.data) return null;
    
    const bio1 = this.props.data.bio1;
    const bio2 = this.props.data.bio2;
    const bio3 = this.props.data.bio3;
    const profile = "images/" + this.props.data.image;


    return (
      <section id="about">
          <div className="row">
              <h2><span>About us</span></h2>
              
              <Fade duration={3000}>
              <div className="seven column">
                <p>{bio1}</p>
                <p>{bio2}</p>
                <p>{bio3}</p>
              </div>
              <div className="five column"><img class="profile-pic" src={profile} alt=""></img></div>
            </Fade>
          </div>
      
      </section>
    );

  }
}

export default About;
