import React, { Component } from "react";
import emailjs from 'emailjs-com';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      messageSent: false,
    };
  }

  sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_l68cx95', 'template_fey34lh', this.formRef.current, 'ojuq3IJMTbVMQlnZc')
      .then((result) => {
        console.log(result.text);
        this.setState({
          messageSent: true,
        });
      }, (error) => {
        console.log(error.text);
      });
  }

  render() {
    const { messageSent } = this.state;

    return (
      <form ref={this.formRef} onSubmit={this.sendEmail}>
        <div>
          <label>Name</label>
          <input type="text" name="user_name" required />
        </div>
        <div>
          <label>Email</label>
          <input type="email" name="user_email" required />
        </div>
        <div>
          <label>Message</label>
          <textarea name="message" required />
        </div>
        <div>
          {messageSent ? (
            <p>Your message has been sent</p>
          ) : null}
          <label htmlFor="button"></label>
          <input type="submit" value="Send" disabled={messageSent} />
        </div>
      </form>
    );
  }
}

export default ContactForm;
