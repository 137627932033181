import React, { Component } from "react";

class Update extends Component {    
  render() {

    return (
      <section id="update">
        <h3><span>Services</span></h3>
        <p><strong>Salesforce Administrator Services</strong></p>
        <ul style={{ listStyleType: "disc", paddingLeft : '20px'}}>
        <li>User Support tickets</li>
        <li>All aspects of user and license management including:</li>
        <li style={{ listStyleType: "none" }}>
        <ul style={{ listStyleType: "circle" }}>
        <li>New user setup/deactivation</li>
        <li>Roles</li>
        <li>Profiles</li>
        <li>Permissions</li>
        <li>OWD</li>
        <li>Sharing rules</li>
        </ul>
        </li>
        <li>Salesforce configuration changes, including (but not limited to):</li>
        <li style={{ listStyleType: "none" }}>
        <ul style={{ listStyleType: "circle" }}>
        <li>Workflow</li>
        <li>Process Builder</li>
        <li>Flow</li>
        <li>Fields</li>
        <li>Page layouts</li>
        <li>Record types</li>
        <li>Dynamic layouts</li>
        <li>Apps</li>
        <li>Actions</li>
        <li>Custom settings</li>
        <li>Mobile administration</li>
        <li>Dashboards and reports</li>
        </ul>
        </li>
        <li>Sandbox environment creation and setup</li>
        <li>Data management to improve Salesforce data quality, implementing rules and automation as needed</li>
        <li>Working with integrated applications</li>
        </ul>
        
        <p><strong>Salesforce Developement Services</strong></p>
        <ul style={{ listStyleType: "disc", paddingLeft : '20px' }}>
        <li>Develop Apex (classes and triggers), Lightning Components, and Visualforce to extend Salesforce in order to support business requirements</li>
        <li>Testing (unit and systems), and debugging,</li>
        <li>Integrations: Use Salesforce APIs to integrate with other systems used in the organization</li>

        </ul>
      </section>
    );

  }
}

export default Update;