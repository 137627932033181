import React, { Component } from "react";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    return (
      <header id="home">

        
        {/* 
        <ParticlesBg color="#429E9D" num={11} type="cobweb" bg={true} />
        */}
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#How">
                3Step
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#resume">
                Quality Assurance
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </nav>
            {/*
        <div className="logo">
             <img src={logo} ></img>
            </div> */}
      </header>
    );
  }
}

export default Header;
