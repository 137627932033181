import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css/bundle'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Autoplay, Pagination, Navigation } from "swiper";






class Carousel extends Component {
  render() {
    if (!this.props.data) return null;

    const image1 = "images/" + this.props.data.carousel.img1;
    const image2 = "images/" + this.props.data.carousel.img2;

    return (
      <section id="carousel">

        {/* 
        <ParticlesBg color="#429E9D" num={11} type="cobweb" bg={true} />
        */}

      <div className="swipe">
          
          <Swiper
        slidesPerView={1}
        spaceBetween={40}
        loop={true}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><img alt="Slider1" src={image1} ></img></SwiperSlide>
        <SwiperSlide><img alt="Slider2" src={image2} ></img></SwiperSlide>
      </Swiper>
          
          
          
          
          <Swiper
            spaceBetween={40}
            centeredSlides={true}
            slidesPerView={1}
            loop = {true}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay={{
              "delay": 2500,
              "disableOnInteraction": false
            }} pagination={{
              "clickable": true
            }} navigation={true} className="mySwiper">
            <SwiperSlide><img alt="Slider1" src={image1} ></img></SwiperSlide> 
            <SwiperSlide><img alt="Slider2" src={image2} ></img></SwiperSlide>
            </Swiper>
            </div>
      </section>
    );
  }
}

export default Carousel;
